import { useEffect } from 'react';

import { supabase } from '~/libs/supabase';

import { setAuthCookie } from './utils';

export const useAuthListener = () => {
  // Listen to auth changes
  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      await setAuthCookie(session, event);
    });

    return () => {
      authListener?.unsubscribe();
    };
  }, []);
};
