import camelcaseKeys from 'camelcase-keys';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PaymentMethod } from '@stripe/stripe-js';

import { Price, Subscription } from '~/types';
import { AdminData, adminDataSchema } from '~/types/admin';

export const joylineApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/api/' }),
  tagTypes: ['Prices', 'Subscription', 'PaymentMethod', 'AdminData'],
  reducerPath: 'joylineApi',
  endpoints: (builder) => ({
    getPrices: builder.query<Price[], void>({
      providesTags: ['Prices'],
      query: () => `/get-prices`,
    }),
    getSubscription: builder.query<Subscription, void>({
      providesTags: ['Subscription'],
      query: () => `/get-subscription`,
    }),
    getPaymentMethod: builder.query<PaymentMethod, void>({
      providesTags: ['PaymentMethod'],
      query: () => `/get-payment-method`,
    }),
    getAdminData: builder.query<AdminData, { month: number; year: number }>({
      providesTags: ['AdminData'],
      query: ({ month, year }) => `/get-monthly-shipments?month=${month}&year=${year}`,
      transformResponse: (response: unknown) => {
        // Validate unknown data
        adminDataSchema.parse(response);

        return response as AdminData;
      },
    }),
  }),
});

export const { useGetPricesQuery, useGetSubscriptionQuery, useGetPaymentMethodQuery, useGetAdminDataQuery } =
  joylineApi;
