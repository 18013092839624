export const Accordion = {
  baseStyle: {
    container: {
      borderWidth: '1px',
      borderLeftWidth: '2px',
      borderRightWidth: '2px',
      borderColor: 'light-blue',
      _first: {
        borderTopWidth: '2px',
        borderTopRadius: 'md',
      },
      _last: {
        borderBottomWidth: '2px',
        borderBottomRadius: 'md',
      },
    },
    button: {
      p: 4,
      _hover: {
        bg: 'light-blue',
      },
    },
  },
};
