import { FC } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { Provider } from 'react-redux';

import { Alert, AlertIcon, ChakraProvider, Text } from '@chakra-ui/react';

import { useAuthListener } from '~/features/auth';
import { store } from '~/store';
import { theme } from '~/theme';

const MyApp: FC<AppProps> = ({ Component, pageProps }) => {
  useAuthListener();

  return (
    <>
      <Script src='https://www.googletagmanager.com/gtag/js?id=G-Y3RERMXM2T' strategy='afterInteractive' />
      <Script id='google-analytics' strategy='afterInteractive'>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-Y3RERMXM2T');
        `}
      </Script>
      <Head>
        <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
        <link rel='manifest' href='/site.webmanifest' />
        <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#5bbad5' />
        <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1' />
        <meta name='msapplication-TileColor' content='#da532c' />
        <meta name='theme-color' content='#ffffff' />
      </Head>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          {process.env.NEXT_PUBLIC_ENVIRONMENT === 'dev' && (
            <Alert variant='subtle' status='warning'>
              <AlertIcon />
              <Text size='info'>This is a dev environment. Use 424242424242424242 for test credit card number.</Text>
            </Alert>
          )}
          <Component {...pageProps} />
        </ChakraProvider>
      </Provider>
    </>
  );
};

export default MyApp;
