import { alertAnatomy as parts } from '@chakra-ui/anatomy';
import type { PartsStyleFunction, StyleFunctionProps } from '@chakra-ui/theme-tools';

function getBgColor(props: StyleFunctionProps): string {
  const status = props.status as 'success' | 'error';
  const solid = props.variant === 'solid';

  return (
    {
      success: 'green',
      error: 'red',
      info: solid ? 'blue' : 'lighter-gray',
      warning: solid ? 'red' : 'light-orange',
    }[status] ?? 'blue'
  );
}

const variantSolid: PartsStyleFunction<typeof parts> = (props) => {
  return {
    container: { bg: getBgColor(props), borderRadius: 'md' },
  };
};

const variantSubtle: PartsStyleFunction<typeof parts> = (props) => {
  return {
    container: { bg: getBgColor(props), borderRadius: 'md' },
    icon: { w: 4, h: 4 },
  };
};

const variants = {
  solid: variantSolid,
  subtle: variantSubtle,
};

export const Alert = {
  variants,
};

// p={4} bg='lighter-gray' borderRadius='md'
