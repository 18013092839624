import { colors } from './colors';
import { radii } from './radii';
import { shadows } from './shadows';
import { sizes } from './sizes';
import { styles } from './styles';
import { typography } from './typography';

export const foundations = {
  colors,
  radii,
  shadows,
  sizes,
  styles,
  ...typography,
};
