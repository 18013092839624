import * as z from 'zod';

import { recipientSchema } from './recipient';

export const monthlyShipmentSchema = z.object({
  userId: z.string(),
  photos: z.array(
    z.object({
      path: z.string(),
      url: z.string(),
    })
  ),
  recipients: z.array(recipientSchema),
});

export const monthlyShipmentsSchema = z.array(monthlyShipmentSchema);

export type MonthlyShipments = z.infer<typeof monthlyShipmentsSchema>;

export type MonthlyShipment = z.infer<typeof monthlyShipmentSchema>;

export const shippedPhotoSchema = z.object({
  id: z.string(),
  createdAt: z.string().optional(),
  userId: z.string(),
  recipientId: z.string(),
  photoName: z.string(),
  month: z.number(),
  year: z.number(),
});

export const shippedPhotosSchema = z.array(shippedPhotoSchema);

export type ShippedPhotos = z.infer<typeof shippedPhotosSchema>;

export type ShippedPhoto = z.infer<typeof shippedPhotoSchema>;

export const adminDataSchema = z.object({
  monthlyShipments: monthlyShipmentsSchema,
  shippedPhotos: shippedPhotosSchema,
});

export type AdminData = z.infer<typeof adminDataSchema>;
