import * as z from 'zod';

export const billingPeriodSchema = z.union([z.literal('monthly'), z.literal('yearly')]);

export const subscriptionSchema = z.object({
  id: z.string().optional(),
  billingPeriod: billingPeriodSchema,
  recipientCount: z.number(),
  active: z.boolean(),
  canceled: z.boolean(),
  cardDeclined: z.boolean(),
});

export type Subscription = z.infer<typeof subscriptionSchema>;

export type BillingPeriod = z.infer<typeof billingPeriodSchema>;
